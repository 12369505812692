import request from '@/network/request.js'
/**
 * 获取测试分类列表
 */
export function lists_classify(data) {
	return request({
		url: '/admin/interest_classify/lists_classify',
		method: 'post',
		data,
	})
}

/**
 * 增加|修改类型分类
 */
export function add_up_classify(data) {
	return request({
		url: '/admin/interest_classify/add_up_classify',
		method: 'post',
		data,
	})
}

/**
 * 删除|批量类型分类
 */
export function dels_classify(data) {
	return request({
		url: '/admin/interest_classify/dels_classify',
		method: 'post',
		data,
	})
}



